import Swiper from 'swiper';
import 'swiper/swiper.scss';

const testimonials = () => {
  const t = document.querySelector('.b-testimonials');
  if (!t) return;

  const swiper = new Swiper('.b-testimonials', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 64,
    loop: false,
    freeMode: true,
    navigation: {
      nextEl: '.b-testimonials__nav--next',
      prevEl: '.b-testimonials__nav--prev',
    },
  });

  swiper.init();

  document
    .querySelector('.b-testimonials__nav--next')
    .addEventListener('click', () => {
      swiper.slideNext();
    });

  document
    .querySelector('.b-testimonials__nav--prev')
    .addEventListener('click', () => {
      swiper.slidePrev();
    });
};

export default testimonials;
