/*
  Project: Betterstyle Landing
  Author: Sławek Niewiadomski
 */

import 'aos/src/sass/aos.scss';
import heroTextExtendableContent from './blocks/hero-text-extendable-content'; // You can also use <link> for styles

import greet from './modules/greeting';
import faq from './blocks/faq';
import heroText from './blocks/hero-text';
import horizontalCardsSwiper from './blocks/horizontal-cards-swiper';
import testimonials from './blocks/testimonials';
import bibliography from './blocks/bibliography';
import extendableContent from './blocks/extendable-content';
import cta from './blocks/call-to-action';

greet('World');
faq();
heroText();
horizontalCardsSwiper();
bibliography();
extendableContent();
cta();
testimonials();
heroTextExtendableContent();
