const extendableContent = () => {
  const CLASSES = {
    wrapper: 'b-extendable-content',
    button: 'b-extendable-content__button',
    open: 'b-extendable-content--extended',
  };

  const DOM = {
    wrappers: document.querySelectorAll(`.${CLASSES.wrapper}`),
    buttons: document.querySelectorAll(`.${CLASSES.button}`),
  };

  DOM.wrappers.forEach((wrapper) => {
    const buttons = wrapper.querySelectorAll(`.${CLASSES.button}`);
    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        wrapper.classList.toggle(`${CLASSES.open}`);
      });
    });
  });
};

export default extendableContent;
