const faq = () => {
  const CLASSES = {
    item: 'b-faq-item',
    chevron: 'b-faq-item__chevron',
  };

  const DOM = {
    items: document.querySelectorAll(`.${CLASSES.item}`),
  };

  DOM.items.forEach((item) => {
    item.addEventListener('click', () => {
      item.classList.toggle(`${CLASSES.item}--open`);
    });
  });
};

export default faq;
