const bibliography = () => {
  const CLASSES = {
    items: 'b-bibliography__items',
    wrapper: 'b-bibliography',
    closed: 'b-bibliography--closed',
    title: 'b-bibliography__title',
  };

  const DOM = {
    items: document.querySelector(`.${CLASSES.items}`),
    wrapper: document.querySelector(`.${CLASSES.wrapper}`),
    title: document.querySelector(`.${CLASSES.title}`),
  };

  if (!DOM.title) return;

  DOM.title.addEventListener('click', () => {
    DOM.wrapper.classList.toggle(`${CLASSES.closed}`);
  });
};

export default bibliography;
