const heroTextExtendableContent = () => {
  const elements = document.querySelectorAll(
    '.b-hero-text-extendable-content__hero-content p',
  );
  const CLASSES = {
    wrapper: 'b-hero-text-extendable-content',
    button: 'b-hero-text-extendable-content__button',
    open: 'b-hero-text-extendable-content--extended',
  };

  const DOM = {
    wrappers: document.querySelectorAll(`.${CLASSES.wrapper}`),
    buttons: document.querySelectorAll(`.${CLASSES.button}`),
  };

  DOM.wrappers.forEach((wrapper) => {
    const buttons = wrapper.querySelectorAll(`.${CLASSES.button}`);
    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        wrapper.classList.toggle(`${CLASSES.open}`);
      });
    });
  });
  const animate = (element) => {
    const el = element;
    const words = element.innerHTML.split(' ');

    el.innerHTML = words
      .map((word, index) => {
        const i = 0.1 + index * 0.1;
        return `<span style="animation: fade-in 0.5s ${i}s forwards cubic-bezier(0.11,0,0.5,0);">${word}</span>`;
      })
      .join(' ');
  };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }
      animate(entry.target);
      observer.unobserve(entry.target);
    });
  };

  const heroTextObserver2 = new IntersectionObserver(observerCallback, {
    rootMargin: '150px',
    threshold: 1.0,
  });

  elements.forEach((element) => {
    heroTextObserver2.observe(element);
  });
};

export default heroTextExtendableContent;
