const heroText = () => {
  const elements = document.querySelectorAll('.b-hero-text p');

  const animate = (element) => {
    const el = element;
    const words = element.innerHTML.split(' ');

    el.innerHTML = words
      .map((word, index) => {
        const i = 0.1 + index * 0.1;
        return `<span style="animation: fade-in 0.5s ${i}s forwards cubic-bezier(0.11,0,0.5,0);">${word}</span>`;
      })
      .join(' ');
  };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }
      animate(entry.target);
      observer.unobserve(entry.target);
    });
  };

  const heroTextObserver = new IntersectionObserver(observerCallback, {
    rootMargin: '150px',
    threshold: 1.0,
  });

  elements.forEach((element) => {
    heroTextObserver.observe(element);
  });
};

export default heroText;
