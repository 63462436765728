import Swiper from 'swiper';
import 'swiper/swiper.scss';

const horizontalCardsSwiper = () => {
  const classes = {
    next: 'b-horizontal-cards-swiper__nav--next',
    prev: 'b-horizontal-cards-swiper__nav--prev',
    disabled: 'b-horizontal-cards-swiper__nav--disabled',
  };

  const DOM = {
    next: document.querySelector('.b-horizontal-cards-swiper__nav--next'),
    prev: document.querySelector('.b-horizontal-cards-swiper__nav--prev'),
  };

  const swiper = new Swiper('.b-horizontal-cards-swiper', {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 24,
    loop: false,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  });

  function updateNav() {
    if (swiper.isBeginning) {
      DOM.prev.classList.add(classes.disabled);
    } else {
      DOM.prev.classList.remove(classes.disabled);
    }
    if (swiper.isEnd) {
      DOM.next.classList.add(classes.disabled);
    } else {
      DOM.next.classList.remove(classes.disabled);
    }
  }

  if (!document.querySelector('.b-horizontal-cards-swiper')) return;

  swiper.init();
  updateNav(swiper);
  DOM.next.addEventListener('click', () => {
    swiper.slideNext();
    updateNav(swiper);
  });
  DOM.prev.addEventListener('click', () => {
    swiper.slidePrev();
    updateNav(swiper);
  });
};

export default horizontalCardsSwiper;
