const cta = () => {
  const CLASSES = {
    items: 'b-call-to-action',
  };

  const DOM = {
    items: document.querySelectorAll(`.${CLASSES.items}`),
  };

  if (!DOM.items) return;

  DOM.items.forEach((item) => {
    if (item.dataset.scroll) {
      item.addEventListener('click', () => {
        document.querySelector(item.dataset.scroll).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    }
  });
};

export default cta;
